import { Component } from '@angular/core';
import { setTheme } from 'ngx-bootstrap/utils';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'aero-landing-page';
}

import { NgModule } from '@angular/core';
import { LandingPageRoutingModule } from './landingPage.routing.module';
import { CommonModule } from '@angular/common';
import{LandingPageComponent} from './landing-page/landing-page.component';
import{LandingPageFooterComponent} from './landing-page-footer/landing-page-footer.component';
import{LandingPageHeaderComponent} from './landing-page-header/landing-page-header.component';
import{LandingPageLayoutComponent} from './landing-page-layout/landing-page-layout.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PhantomPageComponent } from './phantom-page/phantom-page.component';
@NgModule({
  declarations: [LandingPageComponent,LandingPageFooterComponent,LandingPageHeaderComponent,LandingPageLayoutComponent,PhantomPageComponent],
  imports: [
    LandingPageRoutingModule,
    CommonModule,
    ModalModule.forRoot()
  ],
  exports:[],
  providers: [],

})
export class LandingPageModule { }

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';


@Component({
  selector: 'client-loader',
  templateUrl: './client-loader.component.html',
  styleUrls: ['./client-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientLoaderComponent implements OnInit {
  isShowloading: boolean;
  loaderMessages = [];
  constructor(
  ) {}

  ngOnInit(): void {
  }
}

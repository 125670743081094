import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { OrgSelectComponent } from './components/org-select/org-select.component';
import { PhantomPageComponent } from './landingPageModule';
const routes: Routes = [
  { path: '', component: WelcomeComponent, pathMatch: 'full' },
  { path: 'select-organization', component: OrgSelectComponent },
  { path: 'SupplierPortal', component: PhantomPageComponent, pathMatch: 'full' },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LandingPageModule } from './landingPageModule/landingPage.module';
import { AppComponent } from './app.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { OrgSelectComponent } from './components/org-select/org-select.component';
import { FormsModule } from '@angular/forms';
import { ClientLoaderComponent } from './components/client-loader/client-loader.component'

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    OrgSelectComponent,
    ClientLoaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LandingPageModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Input } from '@angular/core';
import { DataTransmitService } from '../../util/data-transmit.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit,AfterViewInit  {
  resData: any;

@Input('data') set setRespData(data){
  this.resData = data;
}
config:any = {
  animated: true,
  backdrop : 'static',
  keyboard : false,
  class: 'modal-dialog-centered'
}

modalRef:BsModalRef;
@ViewChild('infoMsgTemplate', {static: false}) elementView: ElementRef;

  constructor(private dataService: DataTransmitService, private modalService: BsModalService) {

   }

   ngAfterViewInit() {
    // console.log('Values on ngAfterViewInit():');
    // this.showOutage();
  }

 showOutage(){
   var _this = this;
  _this.modalRef = this.modalService.show(this.elementView,_this.config);
 }
public getImageUrl(obj){
  return "url('"+obj.imgSrc+"')";
}
public scrollTop(){
  window.scrollTo({
    'behavior': 'smooth',
    'left': 0,
    'top': 0
  });

}
public checkBackToTopEvent(){
  var backToTopBtn = document.getElementById("backToTop");
  window.onscroll = function() {
    if (document.body.scrollTop > 300 || document.documentElement.scrollTop > 300) {
      backToTopBtn.classList.add('show');
    } else {
      backToTopBtn.classList.remove('show');
    }
  };
}
  ngOnInit() {
    this.dataService.selObj.subscribe(objreceived => {
      let el = document.getElementById(''+objreceived+'');
      if(el){
        el.scrollIntoView({behavior: "smooth"});
      }
    });
    this.checkBackToTopEvent();
  }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataTransmitService {
  public searchObj = new BehaviorSubject([]);
  public searchObjArry = this.searchObj.asObservable();
  public addToCartObj = new BehaviorSubject([]);
  public addToCartArry = this.addToCartObj.asObservable();
  public orderCheckoutObj = new BehaviorSubject([]);
  public orderCheckoutArry = this.orderCheckoutObj.asObservable();
  public orderConfirmObj = new BehaviorSubject([]);
  public orderConfirmArry = this.orderConfirmObj.asObservable();
  public seleNode = new BehaviorSubject(String);
  public selObj = this.seleNode.asObservable();
  public userDetail = new BehaviorSubject(String);
  public userDetailObj = this.userDetail.asObservable();
  public userOrgBoSel = new BehaviorSubject({});
  public userOrgBoSelObj = this.userOrgBoSel.asObservable();
  constructor() { }
  sendSearchedData(part: any) {
    this.searchObj.next(part);
  }
  sendAddToCartData(cart: any){
    this.addToCartObj.next(cart);
  }
  sendOrderCheckoutData(order: any) {
    this.orderCheckoutObj.next(order);
  }
  sendOrderConfirmData(confirmOrder: any) {
    this.orderConfirmObj.next(confirmOrder);
  }
  clickedNode(node: any) {
    this.seleNode.next(node);
  }
  userLoggedIn(node: any) {
    this.userDetail.next(node);
  }
  userBoSelected(node: any){
    this.userOrgBoSel.next(node);
  }
}

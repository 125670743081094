import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-org-select',
  templateUrl: './org-select.component.html',
  styleUrls: ['./org-select.component.css']
})
export class OrgSelectComponent implements OnInit {
  orgData: string[] = ['Aero Alliance Employee', 'Baker Hughes Employee', 'Vernova Employee',
                      'Authorized Service Provider', 'Supplier', 'GE Aerospace'];
  selectedOrg: string;
  isSSO: string;
  constructor(
    private _router: Router
  ) { }
  onCancel() {
    this._router.navigate(['']);
  }
  onAccountSetUp() {
    window.open(environment.appNavigationURL + this.selectedOrg, '_self');
  }
  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {
  username: string;
  constructor(
    private _router: Router
  ) { }

  ngOnInit() {
    
  }
  login() {
    window.open(`${window.location.origin}/dashboard/`, '_self');
  }
  register() {
    this._router.navigate(['select-organization'])
  }

}

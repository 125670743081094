import { Component, OnInit, Input } from '@angular/core';
import { DataTransmitService } from '../../util/data-transmit.service';

@Component({
  selector: 'app-landing-page-header',
  templateUrl: './landing-page-header.component.html',
  styleUrls: ['./landing-page-header.component.scss']
})
export class LandingPageHeaderComponent implements OnInit {

  isButtonVisible = false;
  @Input('isLoginBtnVisible') set setLoginBtnVisible(value){
    this.isButtonVisible = !!value;
  }
  constructor(private dataService: DataTransmitService) { }
  public scroll(elem){
    this.dataService.clickedNode(elem);
  }

  ngOnInit() {
  }

}

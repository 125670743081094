
import { NgModule } from '@angular/core';
import { Routes, RouterModule} from '@angular/router';
import { LandingPageLayoutComponent } from './landing-page-layout/landing-page-layout.component';
const routes: Routes = [
  {path: " ",component: LandingPageLayoutComponent, loadChildren: () => import('./landingPage.module').then(m => m.LandingPageModule)}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LandingPageRoutingModule { }

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-page-layout',
  templateUrl: './landing-page-layout.component.html',
  styleUrls: ['./landing-page-layout.component.scss']
})
export class LandingPageLayoutComponent implements OnInit {
  resData: any =
  {
  'parallaxObj': [
              {
                'parallax_heading':'About Aero Alliance',
                'parallax_desc':'Aero Alliance is a Joint Venture between GE Vernova and Baker Hughes for the fulfilment of aeroderivative engines, spare parts, repairs, and maintenance services.',
                'parallax_subheading':'',
                'imgSrc':'../../../assets/images/store.jpg'
              },
              {
                'parallax_heading':'Aero Portal Store',
                'phantom': false,
                'parallax_desc':"Licensed partners can login to our Aero Portal Store to view parts, submit orders, track orders, and download reports.",
                'parallax_subheading':'',
                'imgSrc':'../../../assets/images/customerInfo.jpg'
              },
              // {
              //   'parallax_heading':'No Time To Waste:',
              //   'parallax_desc':'Shape & Own Aero JV strategic session, working closely with the Baker Hughes and Company Power channels to understand market dynamics for both units and services and impacts to the Aero JV business',
              //   'parallax_subheading':'Our Products',
              //   'imgSrc':'../../../assets/images/search.jpg'
              // }
  ],
  'hoverObj': [
    {
      'imgSrc': '../../../assets/images/ge-aviation-blog-homepage-tile__DECISION_POINT.jpg',
      'imgDesc': 'Setting The Pace: How Smart-Grid Technology Is Powering The Global Shift To Renewables'
    },
    {
      'imgSrc': '../../../assets/images/WINDEA JULES VERNE_DSC04463_CR_Ulstein.jpg',
      'imgDesc': "Setting The Pace: How Smart-Grid Technology Is Powering The Global Shift To Renewables. We're built to be your full-service additive partner."
    },
    {
      'imgSrc': '../../../assets/images//engine.jpg',
      'imgDesc': " Where research meets reality to propel GE and the world forward."
    },
    {
      'imgSrc': '../../../assets/images/Monr_bourbia.jpg',
      'imgDesc': "Partnering to define the future of industries."
    }
  ],
  'subSectionObj': [
    {
      'heading':'Our Services',
      'subHeading':'Mission',
      'imgSrc': '../../../assets/images/image-1.jpg',
      'imgDesc': 'Our mission is to create long-term value for our shareholders through an attractive dividend and favorable investment performance across economic cycles.'
    },
    {
      'heading':'Strategy',
      'subHeading':'Differentiated Strategy',
      'imgSrc': '../../../assets/images/image-2.jpg',
      'imgDesc': 'Our underserved financing needs of private middle-market companies across a wide range of industries.'
    },
    {
      'heading':'Management',
      'subHeading':'Experienced Management',
      'imgSrc': '../../../assets/images/image-3.jpg',
      'imgDesc': 'Our mission is to create long-term value for our shareholders through an attractive dividend and favorable investment performance across economic cycles.'
    },
    {
      'heading':'Solutions',
      'subHeading':'One-Stop Solutions',
      'imgSrc': '../../../assets/images/image-4.jpg',
      'imgDesc': 'Our capabilities and extensive knowledge to deliver comprehensive solutions to meet the distinct'
    }
  ]

};
  constructor() { }

  ngOnInit() {
  }

}
